"use client";
import { getResolvedImage } from "../../../core/sanityAPI/types";
import type { SanityImageObject } from "@sanity/image-url/lib/types/types";
import { useNextSanityImage } from "next-sanity-image";
import Image, { ImageProps } from "next/image";
import React from "react";
import { sanityClient } from "../../../core/sanityAPI/client";

interface Sizes {
  [key: string]: string;
}

const sizeQueries: Sizes = {
  mobile: "(max-width: 768px)",
  tablet: "(max-width: 1200px)",
  desktop: "",
};

interface ApsImageProps {
  priority?: boolean;
  quality?: ImageProps["quality"];
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
  image: SanityImageObject;
  sizes?: Sizes;
  fill?: boolean;
  onClick?: () => void;
}

export const parseSizes = (sizes: Sizes) => {
  return Object.keys(sizes)
    .map((key) => {
      return `${sizeQueries[key]} ${sizes[key]}`;
    })
    .join(",");
};

export const RemoteImage = React.memo<ApsImageProps>((props) => {
  const sizes = props.sizes ? parseSizes(props.sizes) : undefined;
  const imageProps = useNextSanityImage(sanityClient, props.image);

  const noFill = !props.fill;

  const resolvedImage = getResolvedImage(imageProps);

  if (!resolvedImage) {
    return null;
  }

  const width = props.width || imageProps.width;
  const height = props.height || imageProps.height;

  return (
    <Image
      src={resolvedImage.src}
      sizes={sizes}
      width={noFill ? width : undefined}
      height={noFill ? height : undefined}
      priority={true}
      className={props.className}
      style={noFill ? { maxWidth: "100%", width: "100%", height: "auto" } : undefined}
      alt={props.alt || ""}
      fill={props.fill}
      quality={props.quality}
      onClick={props.onClick}
      fetchPriority="low"
    />
  );
});

RemoteImage.displayName = "ApsImage";
