import { createClient } from "@sanity/client";

const projectId = process.env.NEXT_PUBLIC_SANITY_STUDIO_PROJECT_ID;
const dataset = process.env.NEXT_PUBLIC_SANITY_STUDIO_DATASET;

if (!projectId) {
  throw new Error("Missing env.NEXT_PUBLIC_SANITY_STUDIO_PROJECT_ID");
}

if (!dataset) {
  throw new Error("Missing env.NEXT_PUBLIC_SANITY_STUDIO_DATASET");
}

export const sanityClient = createClient({
  projectId,
  dataset,
  apiVersion: "2023-06-11",
  useCdn: true,
});
