import React, { useEffect, useRef, useState } from "react";
import { RemoteImage } from "@/uikit/remote-image";
import PlayIcon from "../../../svg/icons/iconPlay.svg";
import ReactPlayer from 'react-player';
import IconCross from '../../../svg/icons/IconCross.svg';
import {
    videoContainerStyle,
    videoPlaceholderStyle,
    playButtonStyle,
    videoStyle,
    popupOverlayStyle,
    popupContentStyle,
    closeButtonStyle,
} from "./videoContainer.css";
import { imageDescriptionStyle } from "../baseBlock.css";

interface VideoContainerProps {
    placeholder: any;
    videoUrl: string;
    imageDescription?: string;
}

type State = "preview" | "loading" | "playing";

const VideoContainer: React.FC<VideoContainerProps> = ({
    placeholder,
    videoUrl,
    imageDescription,
}) => {
    const [state, setState] = useState<State>("preview");
    const [isVideoActive, setIsVideoActive] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement>(null);


    const handlePlay = () => {
        setState("loading");
        setIsVideoActive(true);
    };

    const handleClose = () => {
        setState("preview");
        setIsVideoActive(false);
    };

    useEffect(() => {
        if (videoRef.current && isVideoActive) {
            videoRef.current.play().catch((err) => {
                console.error(err.message);
                setState("preview");
            });
        }
    }, [isVideoActive]);

    useEffect(() => {
        if (isVideoActive) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = '';
        }
    }, [isVideoActive]);

    return (
        <div className={videoContainerStyle}>
            {state === "preview" && (
                <>
                    <RemoteImage
                        image={placeholder}
                        className={videoPlaceholderStyle}
                        sizes={{
                            mobile: "100%",
                            tablet: "495px",
                            desktop: "595px",
                        }}
                    />
                    {imageDescription && (
                        <div className={imageDescriptionStyle}>
                            <span>{imageDescription}</span>
                        </div>
                    )}
                    {/* FIXME: Remove the comment when there is a proper link to the video. */}
                    {/* <button className={playButtonStyle} aria-label="play video" onClick={handlePlay}>
                        <PlayIcon />
                    </button> */}
                </>
            )}
            {isVideoActive && (
                <div className={popupOverlayStyle} onClick={handleClose}>
                    <button className={closeButtonStyle} onClick={handleClose}><IconCross /></button>
                    <div className={popupContentStyle} onClick={(e) => e.stopPropagation()}>
                        <ReactPlayer
                            url={videoUrl}
                            playing
                            controls
                            width={800}
                            height={400}
                            className={videoStyle}
                            onReady={() => setState("playing")}
                            onError={() => {
                                console.error("Error playing video");
                                handleClose();
                            }}
                        />
                    </div>
                </div>
            )}
        </div>

    );
};

export default VideoContainer;
