import { SanityImageObject } from "@sanity/image-url/lib/types/types";

export interface ResolvedImage {
  src: string;
  width: number;
  height: number;
}

function isResolvedImage(image: unknown): image is ResolvedImage {
  return (
    typeof image === "object" &&
    image !== null &&
    "src" in image &&
    "width" in image &&
    "height" in image
  );
}

export function getResolvedImage(source?: any): ResolvedImage | null {
  if (!source) {
    return null;
  }

  if (isResolvedImage(source)) {
    return {
      src: source.src,
      width: source.width,
      height: source.height,
    };
  }

  return null;
}
