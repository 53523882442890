import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fstyles%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA3XRy07dQAwG4H2fwtKsQXPzXNzVSXvyGmhyK9ADpVxaoOq7IwexsEdkVvkS2f%2FY5xfuod43f7Lw7wvA2dnHuyMwvvgS3Ffhnn3xSyjSA4Gx1kqMBCbGKBEJjDvkPHrpiSvk5L8fpGf2hMO3QXohMAVLqqppJTDH4TiMR%2BmNwwxxQNV3IjBbTS5P0mcCMw7FVZS%2BEJjZznZWvr5PoBvCxvXjVjZV%2FwdP0nrrq%2FRLAhNqaDFLv2KPAaPayDWByaFMVc35J9d33un6J86zP9Jv2Fc%2B0m95DofxMI7Sf30yt7tP5vZ774tTVve6JzDLzEf6A4FZccVV5XzkOshH%2BhOBadhwUvv9w3MLIUS1l78fdZr0Z95vmtOi5vxCYHDBNamcr5wzYcvqf2f5YnEKQQV1bt9YQ1zUB09g754VBoLSYSRwvlMkcKnTROD7spnAx04Lge%2B7VYLYV2gE2GeYCJztf553VrhwMo0rQekjbHzhtAf%2B%2FwZmp%2FyxtQQAAA%3D%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fuikit%2FCommon%2Fcommon.css.ts.vanilla.css%22%2C%22source%22%3A%22LnE3YmhhMjAgewogIGRpc3BsYXk6IGZsZXg7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjsKICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1fMXM5cmEybGIpOwogIGJvcmRlci1yYWRpdXM6IDRweDsKICBmb250LXNpemU6IDE2cHg7CiAgZm9udC13ZWlnaHQ6IDcwMDsKICBib3gtc2hhZG93OiAwIDVweCAxNXB4IDAgcmdiYSgyNDksIDk3LCAxMjMsIDAuMik7CiAgY3Vyc29yOiBwb2ludGVyOwp9Ci5xN2JoYTIwOmhvdmVyIHsKICBiYWNrZ3JvdW5kLWNvbG9yOiAjZTY1YTcxOwp9Ci5xN2JoYTIxIHsKICBjb2xvcjogdmFyKC0tXzFzOXJhMmxiKTsKICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1fMXM5cmEybDMpOwogIGJveC1zaGFkb3c6IDAgMnB4IDRweCAwIHJnYmEoMCwgMCwgMCwgMC4wNSk7Cn0KLnE3YmhhMjE6aG92ZXIgewogIGJhY2tncm91bmQtY29sb3I6ICNlYmViZWI7Cn0KLnE3YmhhMjIgewogIGxpbmUtaGVpZ2h0OiAxLjMzcHg7CiAgY29sb3I6IHZhcigtLV8xczlyYTJsNCk7Cn0KLnE3YmhhMjMgewogIHRleHQtYWxpZ246IGNlbnRlcjsKfQoucTdiaGEyNCB7CiAgd2lkdGg6IDEwMCU7Cn0KLnE3YmhhMjUgewogIHotaW5kZXg6IDEwOwogIHBhZGRpbmc6IDA7Cn0KLnE3YmhhMjYgewogIGZvbnQtd2VpZ2h0OiA3MDA7Cn0KQG1lZGlhIChtaW4td2lkdGg6IDYwMHB4KSB7CiAgLnE3YmhhMjAgewogICAgZm9udC1zaXplOiAxOHB4OwogIH0KfQpAbWVkaWEgKG1heC13aWR0aDogMTE5OXB4KSB7CiAgLnE3YmhhMjUgewogICAgcGFkZGluZzogMDsKICB9Cn0KQG1lZGlhIChtYXgtd2lkdGg6IDEwMjNweCkgewogIC5xN2JoYTI1IHsKICAgIHBhZGRpbmc6IDA7CiAgfQp9%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fblocks%2FBaseBlock%2FbaseBlock.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA81UwW6jMBS85yssrVbqHlzZYEKgl%2FbS%2F3CwIe4SGxnT0Fb598rGEENoU%2B1pTxF%2B43nzZvxyrw8pKd8R%2BNgA0KhWGKFkDjSvqRGv%2FGFz3twPEOwgR9rDk2DmkIM0SZo%2BAEQO4ItJNi%2FGy9tkASBLACYEWcREiRH6bT8bypiQFTSqycGAmVi2jqVQtdI5%2BIUQCmrprIaf0vQ5Csq7WZkQYnuVShrYineeg2g7qKmF5PDARXUwVqTVNHFkjsNdOnkEmWmg14B0BtiHJo4TM9E2NX3LQVlzp8H%2BQiY0L3xc6mSPaS0qCYXhx3bAwtZQbQL64saQeHXIaJDxjWzmeK90zgQVXBqu7fFL1xpRvsFCScOlCUs%2BXNu06UHkX0BFG%2BvG8LFXmnENNWWia3OQZZk%2Fp8XfSqtOsrV4%2BcX5m3muO7SeaLnkxbsr1jgaWgWvO0F%2Bmm9MrRy12r%2FwwsBSWJ%2FUq7XpvHk8ciYouAv3BVsj%2Frg7s5Wdb5V%2FUucLinjU8lEVqu6O8sHVvghylToIMUZNDxI8TOpj3PrBz1%2BMgaL4n8aIfoSK%2FwdLVt2Ymu%2FG5sFbJVco7lGG9wY6LaGKAFjeAK6mkG7TRQijJU51lIxDXPY1%2BckgeLcSvpCTX4nd%2BNW%2B47%2B%2BkHa1ctDJlhtP9QmTbNTTxQYAAA%3D%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var bgImgStyle = 'rh74fzg';
export var contentWrapperStyle = 'rh74fz4';
export var descriptionBoldStyle = 'rh74fzf';
export var descriptionStyle = 'rh74fze';
export var headerWrapperStyle = 'rh74fz0';
export var imageDescriptionStyle = 'rh74fzc';
export var imageDescriptionWrapper = 'rh74fzb';
export var imgStyle = 'rh74fz3';
export var imgWrapperStyle = 'rh74fz2';
export var infoWrapperStyle = 'rh74fz1 q7bha25';
export var labelStyle = 'rh74fzd';
export var textDescription = _7a468({defaultClassName:'rh74fz8',variantClassNames:{fontStyle:{normal:'rh74fz9',bold:'rh74fza'}},defaultVariants:{},compoundVariants:[]});
export var titleColor = _7a468({defaultClassName:'rh74fz5',variantClassNames:{color:{black:'rh74fz6',blue:'rh74fz7'}},defaultVariants:{},compoundVariants:[]});