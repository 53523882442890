'use client';
import React from "react";

import { Block } from "@/blocks/base";

import { BlockContainer } from "@/blocks/base/block-container";
import { FlexContainer } from "@/uikit/container";
import { Typography } from "@/uikit/Typography";

import {
  infoWrapperStyle,
  headerWrapperStyle,
  contentWrapperStyle,
  labelStyle,
  titleColor,
  textDescription,
  imgWrapperStyle,
} from './baseBlock.css'
import VideoContainer from "./VideoContainer";

const BaseBlock = Block("baseBlock", ({ title, description, heroImage, label, imageDescription, demoVideoUrl }) => {
  return (
    <BlockContainer blockPaddingTop="xxl" layout="centeredColumn" className={headerWrapperStyle}>
      <FlexContainer className={contentWrapperStyle} alignItems="start" justifyContent="between" padding="paddingInline">
        <FlexContainer className={infoWrapperStyle} alignItems="start" direction="column" gap="medium">
          <Typography className={labelStyle} settings={{ tag: "label" }}>
            {label}
          </Typography>
          <Typography settings={{ tag: "h1" }}>
            {title && title.map((part: any, index: any) => (
              <React.Fragment key={part._key}>
                <span className={titleColor({ color: part.color })}>
                  {part.text}
                </span>
                {index < title.length - 1 && ' '}
              </React.Fragment>
            ))}
          </Typography>
          {description && description.map((part: any, index: any) => (
            <span key={index} className={textDescription({
              fontStyle: part.style
            })}>{part.text}</span>
          ))}
        </FlexContainer>
        <FlexContainer direction="column" justifyContent="center" className={imgWrapperStyle}>
          {heroImage && demoVideoUrl && (
            <VideoContainer
              placeholder={heroImage}
              videoUrl={demoVideoUrl}
              imageDescription={imageDescription}
            />
          )}
        </FlexContainer>
      </FlexContainer>
    </BlockContainer>
  );
});

export default BaseBlock;
